import { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Alert, TextField, Button, IconButton, FormControl, InputLabel, Select, MenuItem, Card, CardContent } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import NumberFormat from 'react-number-format';
import BarChart from '../BarChart/BarChart';
import './Option.css';



function Option(props) {

    const [prosLabels, setProsLabels] = useState();
    const [prosValues, setProsValues] = useState();
    const [consLabels, setConsLabels] = useState();
    const [consValues, setConsValues] = useState();
    const [prosTotalWeights, setProsTotalWeights] = useState();
    const [consTotalWeights, setConsTotalWeights] = useState();
    const [netTotalBenefit, setNetTotalBenefit] = useState();
    const [chartLabels, setChartLabels] = useState();
    const [chartValues, setChartValues] = useState();
    const [result, setResult] = useState();
    const [resultVisible, setResultVisible] = useState(false);
    const [emptyAdd, setEmptyAdd] = useState();
    const [emptyAddCon, setEmptyAddCon] = useState();
    const [emptyClick, setEmptyClick] = useState(false);
    const [optionNewTitle, setOptionNewTitle] = useState('');
    const [prosNewTotalWeights, setProsNewTotalWeights] = useState();
    const [consNewTotalWeights, setConsNewTotalWeights] = useState();
    const [newNetTotalBenefit, setNewNetTotalBenefit] = useState();
    const [staticTitle, setStaticTitle] = useState(true);
    const [editableTitle, setEditableTitle] = useState(false);
    const [isCalculated, setIsCalculated] = useState(false);


    const calculate = (e) => {
        const prosLabelsList = inputFieldsPros.map(({ itemPro }) => itemPro);
        const prosValuesList = inputFieldsPros.map(({ itemProWeight }) => itemProWeight);
        const consLabelsList = inputFieldsCons.map(({ itemCon }) => itemCon);
        const consValuesList = inputFieldsCons.map(({ itemConWeight }) => itemConWeight);
        const prosTotalWeights = inputFieldsPros.reduce((total, currentValue) => total = total + currentValue.itemProWeight, 0);
        const consTotalWeights = inputFieldsCons.reduce((total, currentValue) => total = total + currentValue.itemConWeight, 0);
        const netTotalBenefit = prosTotalWeights + consTotalWeights;
        const chartLabelsList = prosLabelsList.concat(consLabelsList);
        const chartValuesList = prosValuesList.concat(consValuesList);

        setProsLabels(prosLabelsList);
        setProsValues(prosValuesList);
        setConsLabels(consLabelsList);
        setConsValues(consValuesList);
        setProsTotalWeights(prosTotalWeights);
        setConsTotalWeights(consTotalWeights);
        setNetTotalBenefit(netTotalBenefit);
        setChartValues(chartValuesList);
        setChartLabels(chartLabelsList);
        setResult(result);
        setEmptyClick(true);

    };


    useEffect(() => {

        const updateValues = () => {
            if (prosTotalWeights > 0 && consTotalWeights < 0 && netTotalBenefit !== '') {
                setIsCalculated(true);
                setResultVisible(true);
                setEmptyClick(false);

                props.newTotalPro(props.id, prosTotalWeights);
                setProsNewTotalWeights(prosTotalWeights);

                props.newTotalCon(props.id, consTotalWeights);
                setConsNewTotalWeights(consTotalWeights);

                props.newTotalNetBenefit(props.id, netTotalBenefit);
                setNewNetTotalBenefit(netTotalBenefit);

            } else {
                setIsCalculated(false);
                setResultVisible(false);
            }
        };
        updateValues();
    },
        [prosLabels, prosValues, consLabels, consValues, prosTotalWeights, consTotalWeights, netTotalBenefit]
    )



    const [inputFieldsPros, setInputFields] = useState([
        { itemPro: '', itemProWeight: '' },
    ]);


    const [inputFieldsCons, setInputFieldsCons] = useState([
        { itemCon: '', itemConWeight: '' },
    ]);



    const handleChangeInput = (index, event) => {
        const values = [...inputFieldsPros];
        values[index][event.target.name] = event.target.value;
        setInputFields(values);
        setEmptyAdd(false);
    }

    const handleChangeInputCon = (index, event) => {
        const values = [...inputFieldsCons];
        values[index][event.target.name] = event.target.value;
        setInputFieldsCons(values);
        setEmptyAddCon(false);
    }

    const lastProFieldItem = inputFieldsPros[inputFieldsPros.length - 1];
    const lastConFieldItem = inputFieldsCons[inputFieldsCons.length - 1];

    const handleAddFields = () => {
        if (lastProFieldItem.itemPro !== '' && lastProFieldItem.itemProWeight !== '') {
            setInputFields([...inputFieldsPros, { itemPro: '', itemProWeight: '' }]);
            setEmptyAdd(false);
        }
        else {
            setEmptyAdd(true);
        }
    }

    const handleRemoveFields = (index) => {
        const values = [...inputFieldsPros];
        values.splice(index, 1);
        setInputFields(values);
    }

    const handleAddFieldsCons = () => {
        if (lastConFieldItem.itemCon !== '' && lastConFieldItem.itemConWeight !== '') {
            setInputFieldsCons([...inputFieldsCons, { itemCon: '', itemConWeight: '' }]);
            setEmptyAddCon(false);
        }
        else {
            setEmptyAddCon(true);
        }
    }

    const handleRemoveFieldsCons = (index) => {
        const values = [...inputFieldsCons];
        values.splice(index, 1);
        setInputFieldsCons(values);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const editTitle = () => {
        setEditableTitle(true);
        setStaticTitle(false);
    }


    const handleChangeTitleInput = (event) => {
        setOptionNewTitle(event.target.value);
    }

    const handleTitleChangeSubmit = (event) => {
        event.preventDefault();
        if (!optionNewTitle.trim()) {
            return;
        }
        props.editOption(props.id, optionNewTitle);
        setOptionNewTitle('');
        setEditableTitle(false);
        setStaticTitle(true);
    }

    const cancenlChangeTitle = () => {
        setEditableTitle(false);
        setStaticTitle(true);
    }

    //const numberOfPros = inputFieldsPros.length;
    //const numberOfCons = inputFieldsCons.length;



    return (
        <section className="optionsSection">
            <Card>
                <CardContent>
                    <div className="optionNameSection">
                        <p className="caption">{props.optionCaption}</p>
                        <form onSubmit={handleTitleChangeSubmit} className={`editTitleForm ${editableTitle ? "showInlineBlock" : "hidden"}`}>

                            <div className={`textInputWrapper editTitleInput`} >
                                <TextField
                                    name="optionTitle"
                                    className="textInput"
                                    label={"Edit option name"}
                                    variant="outlined"
                                    onChange={event => handleChangeTitleInput(event)}
                                />
                            </div>
                            <div className="buttonGroup">
                                <div className={`inputWrapper titleEditButton`}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        className="iconButtons iconAdd"
                                        startIcon={<CancelOutlinedIcon />} size="small"
                                        onClick={() => cancenlChangeTitle()}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className={`inputWrapper titleEditButton`}>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        color="tertiaryDark"
                                        className="iconButtons iconAdd"
                                        startIcon={<SaveIcon />} size="small"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>

                        </form>

                        <div className="editableOptionNameArea">
                            <h2 className={`optionName ${staticTitle ? "showInlineBlock" : "hidden"}`}>{props.optionTitle}</h2>
                            <div className={`inputWrapper editButton ${staticTitle ? "showInlineBlock" : "hidden"}`}>
                                <Button
                                    variant="outlined"
                                    color="tertiaryDark"
                                    className="iconButtons iconAdd"
                                    startIcon={<EditIcon />} size="small"
                                    onClick={() => editTitle()}
                                >
                                    Rename
                                </Button>
                            </div>
                        </div>

                    </div>


                    <p className="optionInstructions">Enter at least 1 pro and 1 con for this option.</p>
                    <div className="formSectionContainer">
                        <form onSubmit={handleSubmit}>
                            <div className="formSection prosSection">
                                <h3>Add pros</h3>
                                {inputFieldsPros.map((inputField, index) => (
                                    <div key={index} className={'inputRow' + index.toString()}>
                                        <div className="inputWrapper textInputWrapper">
                                            <TextField
                                                name="itemPro"
                                                id={props.id}
                                                key={props.id}
                                                className="textInput"
                                                label={"Pro " + (index + 1)}
                                                variant="outlined"
                                                value={inputField.itemPro}
                                                onChange={event => handleChangeInput(index, event)}
                                            />
                                        </div>
                                        <div className="inputWrapper dropdownWrapper">
                                            <FormControl className="dropdown">
                                                <InputLabel>Weight</InputLabel>
                                                <Select
                                                    name="itemProWeight"
                                                    value={inputField.itemProWeight}
                                                    label="Weight"
                                                    onChange={event => handleChangeInput(index, event)}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>


                                        <div className="inputWrapper removeButton">
                                            <IconButton
                                                className={"removeIconButton removeIconButton" + index.toString()}
                                                onClick={() => handleRemoveFields(index)}
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                        </div>
                                    </div>

                                ))}
                                <div className="inputWrapperAdd">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="iconButtons iconAdd"
                                        startIcon={<AddIcon />} size="medium"
                                        onClick={() => handleAddFields()}
                                    >
                                        Add pro
                                    </Button>
                                </div>

                                <div className={`errorMessageContainer ${emptyAdd ? "show" : "hidden"}`}>
                                    <Alert severity="error">You must enter the pro item name and its weight before adding another pro.</Alert>
                                </div>



                            </div>
                            <div className="formSection consSection">
                                <h3>Add cons</h3>
                                {inputFieldsCons.map((inputField, index) => (
                                    <div key={index} className={'inputRow' + index.toString()}>
                                        <div className="inputWrapper textInputWrapper">
                                            <TextField
                                                name="itemCon"
                                                className="textInput"
                                                label={"Con " + (index + 1)}
                                                variant="outlined"
                                                value={inputField.itemCon}
                                                onChange={event => handleChangeInputCon(index, event)}
                                            />
                                        </div>
                                        <div className="inputWrapper dropdownWrapper">
                                            <FormControl className="dropdown">
                                                <InputLabel>Weight</InputLabel>
                                                <Select
                                                    name="itemConWeight"
                                                    value={inputField.itemConWeight}
                                                    label="Weight"
                                                    onChange={event => handleChangeInputCon(index, event)}
                                                >
                                                    <MenuItem value={-1}>-1</MenuItem>
                                                    <MenuItem value={-2}>-2</MenuItem>
                                                    <MenuItem value={-3}>-3</MenuItem>
                                                    <MenuItem value={-4}>-4</MenuItem>
                                                    <MenuItem value={-5}>-5</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>


                                        <div className="inputWrapper removeButton">
                                            <IconButton
                                                className={"removeIconButton removeIconButton" + index.toString()}
                                                onClick={() => handleRemoveFieldsCons(index)}
                                            >
                                                <RemoveIcon />
                                            </IconButton>
                                        </div>
                                    </div>

                                ))}
                                <div className="inputWrapperAdd">

                                    <Button
                                        variant="contained"
                                        align="right"
                                        color="secondary"
                                        className="iconButtons iconAdd"
                                        startIcon={<AddIcon />} size="medium"
                                        onClick={() => handleAddFieldsCons()}
                                    >
                                        Add con
                                    </Button>
                                </div>

                                <div className={`errorMessageContainer ${emptyAddCon ? "show" : "hidden"}`}>
                                    <Alert severity="error">You must enter the con item name and its weight before adding another con.</Alert>
                                </div>

                            </div>



                            <div className="sendButton">
                                <Button
                                    variant="outlined"
                                    color="tertiaryDark"
                                    type="submit"
                                    startIcon={<CalculateOutlinedIcon />} size="large"
                                    onClick={calculate}
                                //style={{ color: '#ffffff' }}
                                >
                                    Calculate
                                </Button>
                            </div>
                        </form>

                        <div className={`errorMessageContainer calculateError ${emptyClick ? "show" : "hidden"}`}>
                            <Alert severity="error">You must enter at least 1 pro and 1 con, and complete all pros and cons added before calculating.</Alert>
                        </div>
                    </div>


                    <div className={`resultsSection ${resultVisible ? "" : " hidden"}`}>

                        <h2>Results for: {props.optionTitle}</h2>

                        {/* <div className="test">
                            <p>{props.prosTotal}</p>
                        </div> */}

                        <div className="chartSection">
                            <BarChart
                                chartLabels={chartLabels}
                                chartValues={chartValues}
                            />
                        </div>
                        <div className="resultsStatsSection">

                            <TableContainer sx={{ border: 1, borderColor: 'grey.400', borderRadius: 1 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: '#094067' }}>
                                                Summary for {props.optionTitle}
                                            </TableCell>
                                            <TableCell align="right" sx={{ color: '#094067' }}>
                                                Weight
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ color: '#505B68' }}>
                                                Total pros' weight:
                                            </TableCell>
                                            <TableCell align="right" sx={{ color: '#505B68' }}>
                                                <NumberFormat
                                                    value={prosTotalWeights}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={''}
                                                    renderText={(value, props) => <div {...props}>{value}</div>}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ color: '#505B68' }}>
                                                Total cons' weight:
                                            </TableCell>
                                            <TableCell align="right" sx={{ color: '#505B68' }}>
                                                <NumberFormat
                                                    value={consTotalWeights}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={''}
                                                    renderText={(value, props) => <div {...props}>{value}</div>}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow sx={{ backgroundColor: 'grey.50' }}>
                                            <TableCell sx={{ color: '#353C45', fontWeight: 500 }}>

                                                Net benefit (pros - cons)
                                            </TableCell>
                                            <TableCell align="right" sx={{ color: '#353C45', fontWeight: 500 }}>
                                                <NumberFormat
                                                    value={netTotalBenefit}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={''}
                                                    renderText={(value, props) => <div {...props}>{value}</div>}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>





                        </div>
                    </div>
                </CardContent>
            </Card>

        </section>
    );
}

export default Option;