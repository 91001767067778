import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

const DATA = [
  { id: 'option-1', optionCaption: 'Option 1', optionTitle: 'Option 1', prosTotalWeights: '', consTotalWeights: '', netTotalBenefit: '', },
  { id: 'option-2', optionCaption: 'Option 2', optionTitle: 'Option 2', prosTotalWeights: '', consTotalWeights: '', netTotalBenefit: '', },
];

ReactDOM.render(
  <React.StrictMode>
    <App options={DATA} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
