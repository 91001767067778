
import { Container, Grid, Button, Alert, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Card, CardContent } from '@mui/material';
import CompareIcon from '@mui/icons-material/Compare';
import { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import './App.css';
import Option from './components/Option/Option';
import AdSense from 'react-adsense';




function App(props) {
  const [winner, setWinner] = useState();
  const [winnerTitle, setWinnerTitle] = useState();
  const [options, setOptions] = useState(props.options);
  const [totalPro, setTotalPro] = useState(props.options.prosTotalWeights);
  const [totalCon, setTotalCon] = useState(props.options.consTotalWeights);
  const [totalNetBenefit, setTotalNetBenefit] = useState(props.options.netTotalBenefit);
  const [comparison, setComparison] = useState(false);
  const [compareValidation, setCompareValidation] = useState(true);

  const option1 = options[0];
  const option1Title = options[0].optionTitle;
  const option2 = options[1];
  const option2Title = options[1].optionTitle;
  const tie = null;
  const tieMessage = "It's a tie!";

  const theme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#4091c9',
      },
      primaryLight: {
        main: '#9dcee2',
      },
      primaryDark: {
        main: '#1368aa',
      },
      primaryDarker: {
        main: '#094067',
      },
      secondary: {
        main: '#EF4565',
      },
      secondaryLight: {
        main: '#f29479',
      },
      secondaryLighter: {
        main: '#fedfd4',
      },
      secondaryDark: {
        main: '#ef3c2d',
      },
      secondaryDarker: {
        main: '#cb1b16',
      },
      secondaryDarkest: {
        main: '#65010c',
      },
      tertiary: {
        main: '#7221B0',
      },
      tertiaryDark: {
        main: '#3E6D8E',
      },
      white: {
        main: '#ffffff',
      }
    },
    typography: {
      fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
    },
  });



  function editOption(id, optionNewTitle) {
    const editedOptionsList = options.map(option => {
      // if this task has the same ID as the edited task
      if (id === option.id) {
        //
        return { ...option, optionTitle: optionNewTitle }
      }
      return option;
    });
    setOptions(editedOptionsList);
    setComparison(false);
  }

  function newTotalPro(id, prosNewTotalWeights) {
    const editedOptionsList = options.map(option => {
      // if this task has the same ID as the edited task
      if (id === option.id) {
        //
        return { ...option, prosTotalWeights: prosNewTotalWeights }
      }
      return option;
    });
    setTotalPro(totalPro);
    setOptions(editedOptionsList);
    setComparison(false);
  }

  function newTotalCon(id, consNewTotalWeights) {
    const editedOptionsList = options.map(option => {
      // if this task has the same ID as the edited task
      if (id === option.id) {
        //
        return { ...option, consTotalWeights: consNewTotalWeights }
      }
      return option;
    });
    setTotalCon(totalCon);
    setOptions(editedOptionsList);
    setComparison(false);
  }


  function newTotalNetBenefit(id, newNetBenefit) {
    const editedOptionsList = options.map(option => {
      // if this task has the same ID as the edited task
      if (id === option.id) {
        //
        return { ...option, netTotalBenefit: newNetBenefit }
      }
      return option;
    });
    setTotalNetBenefit(totalNetBenefit);
    setOptions(editedOptionsList);
    setComparison(false);
  }

  const optionsList = options.map(option => (
    <Grid key={option.id} item xs={12} sm={6} >
      <Option
        id={option.id}
        key={option.id}
        optionCaption={option.optionCaption}
        optionTitle={option.optionTitle}
        chartLabels={option.chartLabels}
        chartValues={option.chartValues}
        prosTotalWeights={option.prosTotalWeights}
        consTotalWeights={option.consTotalWeights}
        netTotalBenefit={option.netTotalBenefit}
        editOption={editOption}
        newTotalPro={newTotalPro}
        newTotalCon={newTotalCon}
        newTotalNetBenefit={newTotalNetBenefit}
      />
    </Grid>
  ));

  const findWinner = (e) => {
    e.preventDefault();
    if (netBenefitOption1 > netBenefitOption2 && netBenefitOption1 !== '' && netBenefitOption2 !== '') {
      setWinner(option1);
      setComparison(true);
      setCompareValidation(true);
      setWinnerTitle(option1Title);
    } else if (netBenefitOption1 === netBenefitOption2 && netBenefitOption1 !== '' && netBenefitOption2 !== '') {
      setWinner(tie);
      setComparison(true);
      setCompareValidation(true);
      setWinnerTitle(tieMessage);
    } else if (netBenefitOption1 < netBenefitOption2 && netBenefitOption1 !== '' && netBenefitOption2 !== '') {
      setWinner(option2);
      setComparison(true);
      setCompareValidation(true);
      setWinnerTitle(option2Title);
    } else {
      setWinner(null);
      setComparison(false);
      setCompareValidation(false);
      setWinnerTitle('');
    }
  }


  const netBenefitsList = options.map((option, index) => (
    <TableCell key={index} netbenefit={option.netTotalBenefit} align="center" >
      {option.netTotalBenefit}
    </TableCell>
  ));


  const netBenefitOption1 = netBenefitsList[0].props.netbenefit;
  const netBenefitOption2 = netBenefitsList[1].props.netbenefit;



  return (
    <Container className="App" maxWidth="lg">
      <ThemeProvider theme={theme}>
        <Grid container spacing={3} className="mainContent">
          <Grid item xs={12} md={8}>
            <section className="intro">
              <h1 className='color_primary__darker'>Pros and cons comparison tool</h1>
              <p>The pros and cons calculator lets you compare 2 options and find a winner. With this tool you can create as many pros and cons as you like, and assign them a weight or a score out of 5.</p>
              <p>When you hit the <em><strong>Calculate</strong></em> button for an option, it adds up all pros and cons you entered, and also calculates the net weight of all pros minus all cons.</p>
              <p>Once you entered at least 1 pro and 1 con for each option, you can hit the <em><strong>Compare</strong></em> button to analyse both of those options and see which one is the winner.</p>
              <p>Enter as many pros and cons as you like, just remember to assign a weight to each one.</p>
            </section>
            <section className="optionsListSection">
              <h3>Compare pros and cons</h3>
              <Grid container spacing={2}>
                {optionsList}
              </Grid>
            </section>


            <div className="compareButtonSection">
              <Button
                variant="contained"
                color="tertiaryDark"
                startIcon={<CompareIcon />} size="large"
                style={{ color: '#ffffff' }}
                onClick={findWinner}
              >
                Compare
              </Button>
            </div>
            <div className={`errorMessageContainer ${compareValidation ? "hidden" : "show"}`}>
              <Alert severity="error">You must complete both options before comparing them.</Alert>
            </div>
            {comparison
              ?
              <Card className="comparisonResult">
                <CardContent>
                  <div>
                    {winner
                      !== tie && <h2><span className="winnerTitle">{winnerTitle}</span> is the winner!</h2>
                    }
                    {winner
                      === tie && <h2>It's a tie!</h2>
                    }


                    <TableContainer sx={{ border: 1, borderColor: 'grey.400', borderRadius: 1 }} >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ color: '#094067', fontWeight: 500 }}>
                              Comparison item
                            </TableCell>
                            {options.map((option, index) => (
                              <TableCell key={index} align="center" sx={{ color: '#094067', fontWeight: 500 }}>
                                {option.optionTitle}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ backgroundColor: 'grey.50' }}>
                            <TableCell sx={{ color: '#353C45', fontWeight: 500 }}>
                              Net benefit (pros - cons)
                            </TableCell>
                            {netBenefitsList}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>





                  </div>
                </CardContent>
              </Card>
              : null

            }
            <div className='adContainer_inPage'>
              <AdSense.Google
                client='ca-pub-7995818988036153'
                slot='3515046552'
                style={{ display: 'block' }}
                format='auto'
                responsive='true'
                //layoutKey='-gw-1+2a-9x+5c'
                className='adSlot'
              />
            </div>

          </Grid>
          <Grid item xs={12} md={4}>
            <aside className="sidebar">
              <h3>What is a Pros and Cons analysis</h3>
              <p>A popular management tool for evaluating different options and potential outcomes, the pros and cons analysis is a great way to ponder decisions in a rational way.</p>
              <AdSense.Google
                client='ca-pub-7995818988036153'
                slot='1457350113'
                style={{ display: 'block' }}
                format='auto'
                responsive='true'
                //layoutKey='-gw-1+2a-9x+5c'
                className='adSlot'
              />
              <p>When used alongside other decision-making tools, a pros and cons list is quick and simple tool used for effective decision-making.</p>
              <p>However, a pros and cons list is not a perfect tool, and should not be used as the only method for making decisions. Self-awareness and trusting your instincts are also essential when making high-stakes decisions.</p>
              <p><a href="/" title="History of pros cons">Read more about the history of pros and cons</a></p>
            </aside>
          </Grid>
        </Grid>





      </ThemeProvider>


    </Container >
  );
}

export default App;
