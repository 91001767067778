
import { Bar } from "react-chartjs-2";
import "./BarChart.css";

const BarChart = ({ chartLabels, chartValues }) => {

    const chartOptions = {
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                        drawBorder: false,
                        borderDash: [3, 3],
                        zeroLineColor: "blue"
                    },
                    categoryPercentage: 0.7,
                    barPercentage: 0.9,
                    ticks: {
                        beginAtZero: true
                    }
                }
            ],
            yAxes: [
                {
                    display: false,
                    gridLines: {
                        display: false,
                        zeroLineColor: "transparent"
                    },
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        },
        plugins: {
            title: {
                display: false,
                text: 'Options pros and cons'
            },
            legend: {
                display: false
            },
            scaleShowHorizontalLines: false,
            scaleGridLineColor: "rgba(0,0,0,.05)",
        },
        scaleShowGridLines: false,
        scaleBeginAtZero: false,
        maintainAspectRatio: false,

    };

    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: 'Weight',
                data: chartValues,
                borderRadius: 4,
                backgroundColor: function (context) {
                    var index = context.dataIndex;
                    var value = context.dataset.data[index];
                    return value < 0 ? '#EF4565' :  // draw negative values in one color
                        // else, alternate values in other color
                        '#4091c9';
                }
            },

        ]
    };


    return (
        <div className="chart-wrapper">
            <Bar
                data={data}
                options={chartOptions}
            />
        </div>

    );
};

export default BarChart;